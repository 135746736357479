import { FuseNavigationItem } from '@fuse/components/navigation';
import { oAuthNavigation } from './apps/o-auth/o-auth.navigation';
import { iamNavigation } from './apps/iam/iam.navigation';
import { queueManagerNavigation } from './apps/queue-manager/queue-manager.navigation';
import { orionNavigation } from './apps/orion/orion.navigation';
import { auditingNavigation } from './apps/auditing/auditing.navigation';
import { toolsNavigation } from './apps/tools/tools.navigation';

export const adminNavigation: FuseNavigationItem[] = [
    orionNavigation,
    oAuthNavigation,
    iamNavigation,
    queueManagerNavigation,
    auditingNavigation,
    toolsNavigation
];